<template>
  <div>
    <div class="nav-box">
      <span class="nav-off" @click="navoff">X</span>
      <div class="box-left">
        <ul class="box-left-ul">
          <li class="box-left-nav active">个人介绍</li>
          <li class="box-left-nav" title="待开发">学习背景</li>
          <li class="box-left-nav" title="待开发">前端作品</li>
          <li class="box-left-nav" title="待开发">ps作品</li>
          <li class="box-left-nav" title="待开发">移动端</li>
          <li class="box-left-nav" title="待开发">小程序</li>
          <li class="box-left-nav" title="待开发">阅读小楼</li>
          <li class="box-left-nav" title="待开发">小作日记</li>
        </ul>
      </div>
      <div class="box-right">
        <ul class="box-right-ul">
          <li class="box-right-list">
            <p class="box-right-info">节点一</p>
            <p class="box-right-item" title="系统访问测试账户：js002 密码：1-6">
              
              <a href="http://2026.link:82/"><span class="box-right-exhibit ">Html版</span></a>
              <a href="http://2026.link:81/"><span class="box-right-exhibit active">移动端</span></a>
              <a href="http://2026.link:83/"><span class="box-right-exhibit ">仿xmall商城</span></a>
              <a href="http://2026.link:8086/"><span class="box-right-exhibit">成绩系统</span></a>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
            </p>
          </li>
          <li class="box-right-list">
            <p class="box-right-info">节点一</p>
            <p class="box-right-item">
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
            </p>
          </li>
          <li class="box-right-list">
            <p class="box-right-info">节点一</p>
            <p class="box-right-item">
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
              <span class="box-right-exhibit">节点一</span>
            </p>
          </li>
        </ul>
      </div>
      <!-- <div class="box-hood"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number:0,
    };
  },
  methods: {
    navoff() {
      this.$emit("Mind");
    },
    getdom() {
      let odom = document.querySelectorAll(".box-left-nav");
      for(let i=0;i<odom.length;i++){
        odom[i].onclick=function(){
          for(let j=0;j<odom.length;j++){
            if(j==i){
              odom[j].className='box-left-nav active';
            }else{
              odom[j].className='box-left-nav';
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getdom();
  },
};
</script>
<style lang="scss" scoped>
ul li {
  list-style: none;
}
.nav-box {
  position: absolute;
  top: -100px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 888px;
  height: 559px;
  color: white;
  background: rgba($color: #0c0f11, $alpha: 0.25);
  z-index: 100;
  box-shadow: 0px 0px 51px -37px #c7a15a3b;
  transform: scale(0.8);
  .box-left {
    float: left;
    width: 20%;
    height: 100%;
    background-color: rgba($color: #0c0f11, $alpha: 0.35); //浏览不支持时显示
    background-image: linear-gradient(
      30deg,
      rgba(5, 5, 5, 0.315) 10%,
      rgba(248, 241, 241, 0.308) 50%,
      rgba(5, 5, 5, 0.315) 70%,
      rgba(221, 220, 203, 0.308) 100%
    );
    border-right: 1px solid rgba($color: #fffefc, $alpha: 0.35);
    .box-left-ul {
      padding: 20px 0px 30px;
      .box-left-nav {
        margin-top: 1px;
        width: 100%;
        height: 41px;
        line-height: 41px;
        text-align: center;
        font-size: 13px;
        letter-spacing: 1px;
        color: rgba(250, 249, 248, 0.829);
        cursor: pointer;
        &.active {
          color: rgba(253, 252, 253, 0.932);
          background-color: rgba(19, 18, 18, 0.863);
          border-top: 1px solid rgba(255, 255, 255, 0.911);
          border-bottom: 1px solid rgba(255, 255, 255, 0.911);
          border-radius: 21px;
          font-weight: 600;
        }
      }
    }
  }
  .box-right {
    float: left;
    margin-left: -1px;
    width: 80%;
    height: 100%;
    .box-right-ul {
      padding: 20px 0px 0px 30px;

      .box-right-list {
        margin-bottom: 4px;
        .box-right-info {
          margin: 0;
          height: 41px;
          line-height: 39px;
          text-align: left;
          font-size: 15px;
        }
        .box-right-item {
          margin: 0;
          height: 40px;
          text-align: left;
          .box-right-exhibit {
            display: inline-block;
            width: 13%;
            text-align: center;
            font-size: 13px;
            letter-spacing: 1px;
            background-image: -webkit-gradient(
              linear,
              left 0,
              right 0,
              from(rgba(248, 248, 248, 0.904)),
              to(rgba(219, 217, 217, 0.911))
            );
            -webkit-background-clip: text;

            /*text-fill-color会覆盖color所定义的字体颜色： */

            -webkit-text-fill-color: transparent;
            &.active{
              font-weight: 800;
              color: beige;
              background-color: black;
              border-top: 1px solid white;
              border-bottom:1px solid white ;
              border-radius: 6px;
              padding: 5px 0;
              
            }
          }
        }
      }
    }
  }
  .nav-off {
    position: absolute;
    right: 2px;
    top: -30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    border-radius: 10px;
    color: rgba(129, 16, 16, 0.993);
    background: rgba(59, 58, 56, 0.144);
    border-top: 1px solid white;
    cursor: pointer;
    opacity: 0;
    animation: offshow 1s 3s forwards;
  }
}
@keyframes offshow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}
</style>