function Deng(){
    this.top=800;
    this.left=Math.random()*1100;
    this.score=parseInt(Math.random()*7)+1;
    this.inti();
    this.fly();
}
// 初始化
Deng.prototype.inti=function(){
    // 创建元素
    this.dom=document.createElement("div");
    this.dom.className='deng';
    // 设置元素位置
    this.dom.style.top=this.top+'px';
    this.dom.style.left=(this.left+10)+'px';
    // 设置元素背景图片位置
    var positionD= this.score>=3?'left':'right';
    this.dom.style.backgroundPosition=positionD + " top";
    
    // 添加到哪里
    if(document.getElementsByClassName("box-wrapa")[0]){
        document.getElementsByClassName("box-wrapa")[0].appendChild(this.dom);
    }
    //绑定事件
    var self=this;
    this.dom.onclick=function(){
        self.boom(2);
        new Deng();
    }
}
// 移动
Deng.prototype.fly=function(){
    var self=this;
    var num=800*Math.random()-150;
    this.timer=setInterval(()=>{
        self.top-=1;
        self.dom.style.top=self.top+'px';
        if(self.top<-120){
            self.boom(2);
            new Deng();
            }
        else if(self.top<num) self.boom(0);
    },20);
}
//删除
Deng.prototype.boom=function(a){
    clearInterval(this.timer);
    if(a>1)
    if(document.getElementsByClassName("box-wrapa")[0])
    document.getElementsByClassName("box-wrapa")[0].removeChild(this.dom);
}
module.exports=Deng;