<template>
  <div>
    <div class="box-port">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51152302000185"
        ><span class="reporta reportb">川公网安备 51152302000185号</span></a
      >
      <a href="https://beian.miit.gov.cn/#/"
        ><span class="reporta">蜀ICP备2021017635号</span></a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.box-port {
  width: 0px;
  height: 0px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.171);
  border-top: 1px solid rgba(255, 255, 255, 0.849);
  animation: changwh 2.7s 0.5s linear forwards;
  overflow: hidden;
  .reporta {
    display: inline-block;
    height: 23px;
    padding: 0 6px;
    line-height: 23px;
    border-left: 1px solid rgba(77, 252, 115, 0.877);
    border-right: 1px solid rgba(77, 252, 115, 0.87);
    border-radius: 8px;
    letter-spacing: 1px;
    font-size: 12px;
    color: rgba(248, 242, 237, 0.884);
    transform: scale(0.7);
  }
  a + a {
    display: inline-block;
    border-left: 1px dotted rgba(255, 255, 255, 0.253);
  }
  .reportb {
    padding-left: 26px;
    &::before {
      display: block;
      position: absolute;
      content: "";
      top: -27px;
      left: 5px;
      width: 50px;
      height: 50px;
      background: url("./../../assets/img/wj.png") no-repeat left  top 100%;
    }
  }
}

@keyframes changwh {
  0% {
    width: 0px;
    height: 1px;
  }
  60% {
    width: 510px;
    height: 1px;
  }
  100% {
    width: 510px;
    height: 30px;
  }
}
</style>