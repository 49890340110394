<template>
  <div class="box " >
    <loading v-if="isload"></loading>
    <div
      :class="boxWrap"
      v-drag="{ set: Mind }"
      v-show="!isload"
      v-if="deviceV"
    >
    <newa class="newaa"></newa>
      <div class="contents">
        <calendar v-show="!isnav && !isload"></calendar>
        <calla v-if="iscall" class="call"></calla>
      </div>
      <navb v-show="isnav" @Mind="Mind"  class="navb"></navb>
      <div class="footers">
        <reporta></reporta>
      </div>
    </div>
    <div :class="boxWrap" v-else>
      <navc></navc>
      <div class="footers">
        <reporta></reporta>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "./loading.vue";
import navb from "./../../components/nav/index.vue";
import navc from "./../../components/nav/navm.vue";
import calendar from "./../../components/calendar";
import calla from "./../../components/call";
import reporta from "./../../components/report";
import Newa from './newa.vue';
export default {
  data() {
    return {
      isnav: false,
      iscdar: false,
      iscall: true,
      isload: true,
      deviceV: false,
      windowWidth: document.documentElement.clientWidth,
    };
  },
  components: {
    navb,
    calla,
    calendar,
    loading,
    reporta,
    navc,
    Newa,
  },
  methods: {
    Mind() {
      this.isnav = !this.isnav;
      this.iscall = false;
    },
    wSize() {
      //挂载设备宽高
      let that = this;
      window.onresize = function () {
        that.$store.state.screenWidth = document.documentElement.clientWidth; //窗口宽度
        that.$store.state.screenHeight = document.documentElement.clientHeight; //窗口高度
      };
    },
  },
  computed: {
    boxWrap() {
      if (this.windowWidth > 500) {
        console.log("电脑");
        return "box-wrap";
      } else {
        console.log("手机");
        return "mobile-wrap";
      }
    },
  },
  directives: {
    drag(el, binding) {
      let oDiv = el; //当前元素
      //禁止选择网页上的文字
      document.onselectstart = function () {
        return false;
      };
      oDiv.onmousedown = function (e) {
        let oldl, newl, oldt, newt;
        //鼠标按下，计算当前元素距离可视区的距离
        oldl = e.clientX;
        oldt = e.clientY;
        document.onmouseup = function (e) {
          newl = e.clientX;
          newt = e.clientY;
          if (Math.abs(newl - oldl) > 50 && Math.abs(newt - oldt) < 15) {
            // isnav=!isnav;
            binding.value.set();
          }
          document.onmousemove = null;
          document.onmouseup = null;
        };
        //return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false;
      };
    },
  },
  mounted() {
    const bg = new Image();
    const Vw = document.documentElement.clientWidth;
    if (Vw > 500) {
      this.deviceV=true;
      // bg.src = require("./../../assets/img/find.png");
    } 
      bg.src = require("./../../assets/img/findb.png");
 
    bg.onload = () => {
      let that = this;
      setTimeout(() => {
        that.isload = false;
        console.log("加载完成");
      }, 1000);
    };
    //挂载宽高
    this.wSize();
  },
  watch: {
    "$store.state.screenWidth": function (val) {
      //监听屏幕宽度变化
      this.windowWidth = val;
      if(this.windowWidth>500){
        this.deviceV=true;
      }else{
        this.deviceV=false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  position: relative;
  margin: 0 auto;
  width:1300px;
  height:900px;
  .newaa{
    position: relative;
     z-index: 100;
  }
  .contents {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1200px;
    height: 100%;
    .call{
      transform: scale(0.8);
    }
  }
  .footers {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    padding: 10px 160px;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    height: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.76);
    background: rgba(12, 12, 12, 0.137);
    transition: all 0.8s;
    &.footers:hover {
      background: rgba(12, 12, 12, 0.808);
    }
  }
}
</style>