<template>
  <div class="box-wrapa">
    <!-- <div class="stars"></div> -->
    <!-- <div class="deng"></div> -->
  </div>
</template>

<script>
import Star from "./../../assets/js/starsky.js";
import Deng from "./../../assets/js/deng.js";
export default {
  data() {
    return {
      dom: {},
    };
  },
  methods: {},
  mounted() {
    var i = 0;
    var time = setInterval(() => {
      i > 10 ? clearInterval(time) : new Deng();
      i++;
    }, 500);
    for(let j=0;j<800;j++)new Star();
  },
};
</script>
<style lang="scss" scoped>
.box-wrapa {
  position: relative;
  width: 1280px;
  height: 800px;
  margin: 0 auto;
  background: url("./../../assets/img/newbg.png") no-repeat; 
}

</style>