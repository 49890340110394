<template>
  <div>
    <div class="cdar-box" v-show="iscdar">
      <div class="year-box">
        <div class="box-left">
          <span class="box-num">1</span>
          <span class="box-month">January</span>
        </div>
        <div class="box-right">
          <div class="box-year">2022</div>
          <div class="box-years">辛 丑 年</div>
        </div>
      </div>
      <div class="week-box">
        <span>日</span>
        <span>一</span>
        <span>二</span>
        <span>三</span>
        <span>四</span>
        <span>五</span>
        <span>六</span>
      </div>
      <div class="day-box">
        <ul>
          <!-- (li>(span{$@一}+span{初一}))*31 -->
          <li v-for="index of 6" :key="index"></li>
          <li v-for="index of 31" :key="index"><span>{{index}}</span><span>{{timeday[index-1]}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iscdar: true,
      timeday:[
            "元旦","三十","腊月","初二","小寒",
            "初四","初五","初六","初七","初八",
            "初九","初十","十一","十二","十三",
            "十四","十五","十六","十七","大寒",
            "十九","二十","廿一","廿二","廿三",
            "廿四","廿五","廿六","廿七","廿八",
            "除夕"]
      
    };
  },
};
</script>
<style lang="scss" scoped>
ul li {
  list-style: none;
}
.cdar-box {
  position: absolute;
  top: 106px;
  right: 96px;
  width: 308px;
  transform: scale(0.7);
  .year-box {
    color: #161e1d;
    padding: 0px 3px;
    height: 39px;
    background: rgba($color: #a1b0c5, $alpha: 0.45);
    .box-left {
      float: left;
      height: 100%;
      span {
        display: inline-block;
        height: 39px;
        text-align: center;
        line-height: 39px;
      }
      .box-num {
        width: 39px;
        font-size: 23px;
        font-weight: 600;
      }
      .box-month {
        // vertical-align:text-bottom;
        position: relative;
        top: -1px;
        left: -10px;
        line-height: 30px;
        font-size: 13px;
        letter-spacing: 1px;
        transform: scale(0.8);
      }
    }
    .box-right {
      margin-right: -7px;
      float: right;
      height: 100%;
      .box-year {
        padding-top: 7px;
        letter-spacing: 3px;
        font-weight: 600;
      }
      .box-years {
        margin-top: -5px;
        letter-spacing: 5px;
        font-size: 9px;
        font-weight: 600;
        transform: scale(0.7);
      }
    }
  }
  .week-box {
    height: 22px;
    span {
      display: inline-block;
      font-weight: 800;
      font-size: 15px;
      color: #eee;
      margin: 0px 14px;
    }
  }
  .day-box {
    padding: 10px 0px 0px 6px;
    height: 233px;
    color: #161e1d;
    background: rgba($color: #a1b0c5, $alpha: 0.18);
    ul {
      margin: 0;
      padding: 0;
      li {
        float: left;
        width: 39px;
        height: 33px;
        font-size: 10px;
        margin: 0px 4px 7px 0px;
        // border-right: 1px dotted rgba(255,255,255,0.7);
        span {
          display: block;
          text-align: center;
        }
      }
    }
  }
}
</style>