function Star() {
	this.intia();
}
var r = 800;
// 初始化
Star.prototype.intia = function () {
	// 创建元素
	var s = 0.2 + (Math.random() * 1);
	var curR = r + (Math.random() * 300);
	this.dom = document.createElement("div");
	this.dom.className = 'star';
	this.dom.style.transformOrigin = "0 0 " + curR + "px";
	this.dom.style.transform = " translate3d(0,0,-" + curR + "px) rotateY(" + (Math.random() * 360) + "deg) rotateX(" + (Math.random() * -50) + "deg) scale(" + s + "," + s + ")";
	// 添加到哪里
	document.getElementsByClassName('stars')[0].appendChild(this.dom);
}

module.exports=Star;