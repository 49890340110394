<template>
  <div id="body">
    <a href="#">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
      加载中。。。
    </a>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
a {
  display: block;
  padding: 20px;
  width: 100px;
  height: 100px;
  background-color: #2a815dab;
  position: fixed;
  left: 50%;
  top: 45%;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  color: #fff;
  line-height: 100px;
  border-top:2px solid rgba(255, 255, 255, 0.678) ;
  border-bottom: 2px solid rgba(250, 250, 250, 0.747);
  text-decoration: none;
  transform: scale(0.8);
}
a .line1 {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -42px 0 0 -42px;
  border: 2px solid #fff;
  border-radius: 80px 80px 80px 80px;
  border-right-color: transparent;
  border-top-color: transparent;
}
a .line2 {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -52px 0 0 -52px;
  border: 2px solid #fff;
  border-radius: 100px 100px 100px 100px;
  border-right-color: transparent;
  border-left-color: transparent;
}
a .line3 {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -62px 0 0 -62px;
  border: 2px solid #fff;
  border-radius: 120px 120px 120px 120px;
  border-right-color: transparent;
}
@-webkit-keyframes line1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
  50% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
  75% {
    -webkit-transform: rotate(1300deg);
    transform: rotate(1300deg);
  }
  100% {
    -webkit-transform: rotate(2500deg);
    transform: rotate(2500deg);
  }
}
@keyframes line1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
  50% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
  75% {
    -webkit-transform: rotate(1300deg);
    transform: rotate(1300deg);
  }
  100% {
    -webkit-transform: rotate(2500deg);
    transform: rotate(2500deg);
  }
}
a .line1 {
  -webkit-animation: line1 14s ease-in-out 1s infinite alternate;
  animation: line1 15s ease-in-out 1s infinite alternate;
}
@-webkit-keyframes line2 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes line2 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
a .line2 {
  -webkit-animation: line2 3s ease-in-out infinite;
  animation: line2 3s ease-in-out infinite;
}
@-webkit-keyframes line3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
  50% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
  75% {
    -webkit-transform: rotate(1300deg);
    transform: rotate(1300deg);
  }
  100% {
    -webkit-transform: rotate(2500deg);
    transform: rotate(2500deg);
  }
}
@keyframes line3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
  50% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
  75% {
    -webkit-transform: rotate(1300deg);
    transform: rotate(1300deg);
  }
  100% {
    -webkit-transform: rotate(2500deg);
    transform: rotate(2500deg);
  }
}
a .line3 {
  -webkit-animation: line3 20s ease-in-out infinite;
  animation: line3 20s ease-in-out infinite;
}
</style>