<template>
  <div class="box-navb">
    <h2 class="selh2">hello world!</h2>
    <div class="box-right">
      <a href="http://2026.link:82/"
        ><span class="box-right-exhibit">Html版</span></a
      >
      <a href="http://2026.link:81/"
        ><span class="box-right-exhibit active">移动端</span></a
      >
      <a href="http://2026.link:83/"
        ><span class="box-right-exhibit">仿xmall商城</span></a
      >
      <a href="http://2026.link:8086/"
        ><span class="box-right-exhibit">成绩系统</span></a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.box-navb {
  padding: 20px 20px;
  .selh2 {
    color: rgba(255, 255, 255, 0.959);
    letter-spacing: 1px;
  }
  .box-right {
    margin: 40px auto;
    width: 200px;
    .box-right-exhibit {
      display: inline-block;
      margin: 16px 10px;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 13px;
      letter-spacing: 1px;
      color: rgba(51, 145, 207, 0.904);
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-radius: 10px;
      background-color: rgba(37, 20, 20, 0.075);
      &.active {
        color: rgba(255, 255, 255, 0.726);
        border-top: 1px solid rgba(255, 255, 255, 0.829);
        border-bottom: 1px solid rgba(255, 255, 255, 0.836);
      }
      &:hover {
        color: rgba(255, 255, 255, 0.726);
        border-top: 1px solid rgba(255, 255, 255, 0.829);
        border-bottom: 1px solid rgba(255, 255, 255, 0.836);
      }
    }
  }
}
</style>