<template>
  <div class="box-call">
    <h3 class="call-text">按下鼠标滑动-->召唤/关闭 菜单！</h3>
    <img src="./../../assets/img/call.png" alt="" class="call-img" />
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.box-call {
  position: absolute;
  top: 100px;
  width: 300px;
  color: rgba(231, 250, 249, 0.425);
  font-family: "楷体";
  opacity: 0;
  animation:callshow 1s 2s forwards;
  .call-img {
    position: relative;
    width: 30px;
    margin-top:-100px;
    animation:mymove 3s 3s 3 backwards;
  }
}
@keyframes mymove{
    from{
        left: -30px;
    }
    to{
        left:100px;
    }
}
@keyframes callshow{
    from{
        opacity: 0;
    }
    to{
        opacity: 0.9;
    }
}
</style>